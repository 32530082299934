/* General menu styling */
.menu {
  font-family: "Arial", sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffd580; /* Dark background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  color: #000000; /* Light text color */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Menu title */
.menu-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
  border-bottom: 1px solid #9f842e;
  padding-bottom: 10px;
}

/* Menu section */
.menu-section {
  margin-bottom: 30px;
}

.menu-section-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #050505;
  /* border-bottom: 2px solid #7e7e7e; */
  padding-bottom: 5px;
}

/* Individual menu item */
.menu-item {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.menu-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.menu-item-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-item-price {
  font-size: 1rem;
  color: #262626;
}

.menu-item-description {
  font-size: 0.9rem;
  color: #3a3a3a;
}

/* Add spacing between items */
.menu-items > *:not(:last-child) {
  margin-bottom: 15px;
}

/* Scrollbar for smaller screens (optional) */
.menu {
  overflow-y: auto;
  /* max-height: 80vh;  */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #6c6c84 #29293d; /* Firefox */
}

/* Webkit scrollbar */
.menu::-webkit-scrollbar {
  width: 8px;
}

.menu::-webkit-scrollbar-thumb {
  background-color: #6c6c84;
  border-radius: 4px;
}

.menu::-webkit-scrollbar-track {
  background-color: #29293d;
}
